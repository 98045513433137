<template>
  <NuxtLink
    :to="blok?.url?.url ? blok.url.url : `/${locale}`"
    class="mx-2 lg:mx-0 flex items-center snap-center justify-center rounded border-1 text-others-black flex-shrink-0 text-10 font-semibold px-3 py-1.5 uppercase whitespace-nowrap h-52 w-142 lg:h-65 lg:w-210"
    ><NuxtImg
      :preload="true"
      :src="OptimizeStoryBlokImage(blok.image?.filename)"
      class="max-h-6 lg:max-h-9 w-full object-contain"
  /></NuxtLink>
</template>

<script setup>
import { OptimizeStoryBlokImage } from '@/utils/helpers'
defineProps({
  blok: Object,
})

const { locale } = useI18n()
</script>
